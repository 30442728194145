'use client';
import Image from 'next/image';
import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import { useStore } from '@nanostores/react';
import { $userName } from '@lecar/stores/user/user';
import { Dropdown } from 'flowbite-react';
import { signOut } from 'firebase/auth';
import { auth } from '@lecar/firebase/auth';
import { routes } from '../../../utils/routes';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { cn } from '../base/utils';
import LanguageSwitcher from '../language-switcher';
import { useTranslations } from '../../hooks/useTranslations';
import { motion, AnimatePresence } from 'framer-motion';

export const Header = ({
  darkMode = false,
  customClass = '',
}: {
  darkMode?: boolean;
  customClass?: string;
}) => {
  const t: any = useTranslations();
  const [menuOpen, setMenuOpen] = useState(false);
  const { firstName } = useStore($userName);
  const pathname = usePathname();
  const isNewsroom = pathname?.includes('/newsroom');

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const MenuItems = ({ isMobile = false }: { isMobile?: boolean }) => (
    <ul className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-8">
      {[
        { href: routes.HOME, label: t.menu.home },
        { href: routes.ABOUT_US, label: t.menu.about },
        { href: routes.NEWSROOM, label: t.menu.pressRoom },
        { href: routes.CONTACT, label: t.menu.contact },
      ].map((item) => (
        <li key={item.href}>
          <Link
            href={item.href}
            onClick={isMobile ? closeMenu : undefined}
            className={cn(
              'text-base relative group transition-colors duration-300',
              isMobile || darkMode ? 'text-white' : 'text-black',
              'hover:opacity-80'
            )}
          >
            <span className="relative">
              {item.label}
              <span
                className={cn(
                  'absolute -bottom-1 left-0 w-0 h-0.5 transition-all duration-300 group-hover:w-full',
                  isMobile || darkMode ? 'bg-white' : 'bg-black'
                )}
              />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <header
      className={cn(
        'w-full z-30',
        isNewsroom ? 'relative' : 'absolute',
        darkMode && 'bg-black',
        customClass
      )}
    >
      <div
        className={cn(
          'flex items-center justify-between mx-auto max-w-screen-2xl h-28',
          'px-6 lg:px-16',
          darkMode ? 'lg:px-16 px-6' : 'px-8'
        )}
      >
        <Link href={routes.HOME} className="flex items-center">
          <Image
            src="/img/logo.png"
            alt="Logo da Lecar"
            width={182}
            height={32}
            className={cn('w-[182px] h-[32px]', !darkMode && 'invert')}
            priority
          />
        </Link>

        <nav className="items-center hidden space-x-8 md:flex">
          <MenuItems />
          <LanguageSwitcher darkMode={darkMode} />
          {firstName && (
            <Dropdown
              label={
                <div className="flex flex-row items-center space-x-4 cursor-pointer">
                  <FaUserCircle
                    size={24}
                    className={darkMode ? 'text-white' : 'text-black'}
                  />
                  <span className={darkMode ? 'text-white' : 'text-black'}>
                    {firstName}
                  </span>
                </div>
              }
              inline={true}
              arrowIcon={false}
            >
              <Dropdown.Item onClick={handleSignOut}>
                <div className="flex items-center">
                  <FaSignOutAlt className="mr-2" />
                  Sair da conta
                </div>
              </Dropdown.Item>
            </Dropdown>
          )}
        </nav>

        <button
          onClick={toggleMenu}
          className="flex md:hidden focus:outline-none"
          aria-label={menuOpen ? 'Fechar menu' : 'Abrir menu'}
        >
          {menuOpen ? (
            <FiX size={24} className={darkMode ? 'text-white' : 'text-black'} />
          ) : (
            <FiMenu
              size={24}
              className={darkMode ? 'text-white' : 'text-black'}
            />
          )}
        </button>
      </div>

      <AnimatePresence>
        {menuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-20 bg-black/50 backdrop-blur-sm"
              onClick={closeMenu}
            />
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="fixed top-0 left-0 z-30 w-full h-screen p-8 bg-black"
            >
              <div className="flex items-center justify-between mb-12">
                <Link href={routes.HOME} onClick={closeMenu}>
                  <Image
                    src="/img/logo.png"
                    alt="Logo da Lecar"
                    width={182}
                    height={32}
                    className="w-[182px] h-[32px]"
                    priority
                  />
                </Link>
                <button onClick={closeMenu} className="focus:outline-none">
                  <FiX size={24} className="text-white" />
                </button>
              </div>
              <MenuItems isMobile={true} />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
