'use client';

import dynamic from 'next/dynamic';
import { Hero } from '../components/home/Hero';
import { loadStripe } from '@stripe/stripe-js';
import WhatsappCTA from '../components/home/WhatsappCTA';

const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE as string);

const loadDynamicComponent = (
  importFunc: () => Promise<any>,
  exportName: string
) => dynamic(() => importFunc().then((mod) => mod[exportName]));

const InteriorSection = loadDynamicComponent(
  () => import('../components/home/InteriorSection'),
  'InteriorSection'
);
const DesignCarouselSection = loadDynamicComponent(
  () => import('../components/home/DesignCarouselSection'),
  'DesignCarouselSection'
);
const TechnicalSpecsSection = loadDynamicComponent(
  () => import('../components/home/TechnicalSpecsSection'),
  'TechnicalSpecsSection'
);
const EfficiencySection = loadDynamicComponent(
  () => import('../components/home/EfficiencySection'),
  'EfficiencySection'
);
const CarRangeMap = loadDynamicComponent(
  () => import('../components/home/CarRangeMap'),
  'CarRangeMap'
);
const SectionReservation = loadDynamicComponent(
  () => import('../components/home/SectionReservation'),
  'SectionReservation'
);
const Footer = loadDynamicComponent(
  () => import('../components/home/Footer'),
  'Footer'
);

const Index = () => (
  <>
    <div className="relative bg-[#D1D1D1]">
      <Hero />
      <InteriorSection />
      <DesignCarouselSection />
      <EfficiencySection />
      <CarRangeMap />
      <TechnicalSpecsSection />
      <SectionReservation />
      <Footer />
    </div>
    <WhatsappCTA />
  </>
);

export default Index;
